@tailwind base;
@tailwind components;
@tailwind utilities;

@import './theme.css';

.no-tap-highlight {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

#nprogress .bar {
  @apply bg-red-600;
}